import React from 'react';
import './ErrorMessage.less'


// Midlertidig kopi av ContextErrorMessage før den nye ikonløsningen er på plass. 
export default function ErrorMessage({ title, children }) {
    return (
        <div 
        className="ffe-context-message ffe-context-message--error ffe-context-message--compact" 
        style={{marginBottom: '15px'}} 
        aria-label="Advarsel"
        >
            <div className="ffe-context-message-content">
                <div className="ffe-context-message-content__icon">
                    <svg role="img" className="ffe-context-message-content__icon-svg" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <title>Utropstegn</title>
                        <path d="M479.881-163.08q-21.784 0-37.178-15.513-15.394-15.513-15.394-37.297t15.513-37.178q15.513-15.394 37.297-15.394t37.178 15.513q15.394 15.513 15.394 37.297t-15.513 37.178q-15.513 15.394-37.297 15.394Zm-.061-196.151q-19.589 0-33.357-13.896-13.769-13.896-13.769-33.41v-363.078q0-19.514 13.949-33.41 13.949-13.896 33.537-13.896 19.589 0 33.357 13.896 13.769 13.896 13.769 33.41v363.078q0 19.514-13.949 33.41-13.949 13.896-33.537 13.896Z" />
                    </svg>
                </div>
                <div>
                    <h3 className="ffe-context-message-content__header">{title}</h3>
                    <p className="ffe-body-text">{children}</p>
                </div>
            </div>
        </div>
    )
}